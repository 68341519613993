import { configureStore } from "@reduxjs/toolkit";

import userDataReducer from "./slice/userData";
import toastDataReducer from "./slice/toastData";
import sideBarReducer from "./slice/sidebarState";
import filterReducer from "./slice/filterState";
import appStateReducer from "./slice/appState";

export default configureStore({
  reducer: {
    userData: userDataReducer,
    toastData: toastDataReducer,
    sideBarState: sideBarReducer,
    filterState: filterReducer,
    appState: appStateReducer,
  },
});
