import { useDispatch, useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";

import { resetUserData, selectUserData } from "../store/slice/userData";

import LocalStorage from "../../services/LocalStorage";

const useAuth = () => {
  const navigate = useNavigate();
  const userDataDispatch = useDispatch();
  const { user, token } = useSelector(selectUserData);

  const getUserLoginState = () => !!(user?.userType && token?.access);

  const getUserType = () => user?.userType;

  const logoutUser = () => {
    LocalStorage.clear();
    userDataDispatch(resetUserData());
    navigate("/auth", {
      replace: true,
    });
  };

  return { getUserLoginState, getUserType, logoutUser };
};

export default useAuth;
