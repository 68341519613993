export const MAX_AGE = 100;
export const MIN_AGE = 1;

export const BulkUploadUpdateTypes = {
  LEVEL: "level",
  GRADE: "grade",
  SUBMISSIONS: "submissions",
  SHIPMENTS: "shipments",
  MAGAZINE: "magazine",
};

export const enum UserType {
  ADMIN = "admin",
  JUDGE = "judge",
  SALES = "sales",
  INTERNAL_JUDGE = "internal",
  EXTERNAL_JUDGE = "external",
};

export const FeedbackStatus = {
  NOT_GENERATED: "not_generated",
  PENDING: "pending",
  APPROVED: "approved",
  REJECTED: "rejected",
  PUBLISHED: "published",
};
