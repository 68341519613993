import { useCallback, useEffect, useMemo } from "react";

import { Navigate, useLocation } from "react-router-dom";

import { useDispatch } from "react-redux";

import useAuth from "utils/customHooks/useAuth";

import AccountLayout from ".";
import { setAppHomeURL } from "utils/store/slice/appState";
import { UserType } from "constants";

const AuthGuard = ({ allowedUserTypes }) => {
  const location = useLocation();
  const { getUserLoginState, getUserType } = useAuth();

  const isUserLoggedIn = useMemo(() => getUserLoginState(), []);

  const appStateDispatch = useDispatch();

  const getRedirectPath = useCallback(() => {
    const pathname = location.pathname;
    const search = location.search;
    const hash = location.hash;

    const redirectPath = `${pathname}${search}${hash}`;

    return redirectPath;
  }, [location]);

  const showPage = () => {
    const userType = getUserType();

    let homeURL = "/account/master-list";

    switch (userType) {
      case UserType.ADMIN:
        homeURL = "/account/master-list";
        break;
      case UserType.JUDGE:
        homeURL = "/account/score-sheet";
        break;
      case UserType.SALES:
        homeURL = "/account/child-report-card";
        break;
      default:
        homeURL = "/account/master-list";
    }

    appStateDispatch(setAppHomeURL(homeURL));

    return allowedUserTypes.includes(userType) ? (
      <AccountLayout />
    ) : (
      <Navigate to="/unauthorized" />
    );
  };

  return isUserLoggedIn ? (
    showPage()
  ) : (
    <Navigate
      to="/auth"
      replace
      state={{
        redirectTo: getRedirectPath(),
      }}
    />
  );
};

export default AuthGuard;
