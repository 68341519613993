import useAuth from "utils/customHooks/useAuth";

const UnauthorizedUserScreen = () => {
  const { logoutUser } = useAuth();

  return (
    <div className="min-vw-100 min-vh-100 d-flex gap-2 flex-column align-items-center justify-content-center">
      <div className="display-1 dark-blue-text font-satoshi-medium">
        Unauthorized
      </div>
      <div className="fs-4 text-secondary font-satoshi">
        You are not authorized to access the page
      </div>
      <button className="primary-button px-5 mt-4" onClick={logoutUser}>
        Logout
      </button>
    </div>
  );
};

export default UnauthorizedUserScreen;
