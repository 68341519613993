import { createSlice } from "@reduxjs/toolkit";

const sidebarInitialState = {
  homeUrl: "/account/master-list",
};

export const appStateSlice = createSlice({
  name: "appState",
  initialState: sidebarInitialState,
  reducers: {
    setAppHomeURL: (state, action) => {
      state.homeUrl = action.payload;
    },
  },
});

export const selectAppHomeURL = (state) => state.appState.homeUrl;

export const { setAppHomeURL } = appStateSlice.actions;

export default appStateSlice.reducer;
